import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography, Card, Stack, AccordionGroup } from '@mui/joy';
import { isMobile, } from 'react-device-detect';
import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { fetchPinList, deletePin } from '../userDataFetching/pinFetching';
import { PinDeletionModal, } from '../Modals/pinModals';
import { ToggleMultiplePins } from './pinLocationFinding';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';
import { BasicAssets } from '../../assets/basicAssets';

import orangeLocation from '../../assets/orange-location.png'


import refresh from '../../assets/refresh.png'
import '../../css/navbar.css'

const PinListDisplay = ({ closeAll }) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [cardIndex, setCardIndex] = useState(null);
    const [parentAccordionIndex, setParentAccordionIndex] = useState(null);
    const [refreshLoading, setRefreshLoading] = useState(false)
    const {  currentUserRoles, setActiveIcon, activeIcon} = usePosition();
    const { addPin, removePin, pinListPositions, setPinListPositions, setFocusedPin, setPinView } = usePinListPosition();

    const handleDeleteOpen = () =>{
      setOpenDelete(true)
    };
    const handleDeleteClose = () => setOpenDelete(false);
    
    const handleAddNewPin = () => {
      setActiveIcon({
        search:false,
        newPin:true,
        getInTouch:false,
    })
    }

    /*
    const handleUpdateOpen = () =>{
      setOpenUpdate(true)
    };
    const handleUpdateClose = () => setOpenUpdate(false);
    */

    const handleFetchPinList= async() => {
        try {
            console.log('fetching pin list PINSELECTION')
            const {data} = await fetchPinList()
            //console.log(data.listEntries.items)
            //setPinListPositions(data.listEntries.items)
            data.listEntries.items.forEach(({ lat, lng, title, description, id, creator }) => {
              addPin(lat, lng, title, description, id, creator);
            });
            console.log('refresh done')
            setRefreshLoading(false)
            
        } catch (error) {
            console.log('error fetching pins', error)
        }
    }

    const refreshPinList = () => {
      if (pinListPositions.length > 0) {
        console.log('refresh start')
        setRefreshLoading(true)
        pinListPositions.forEach(({title})=>{
          removePin(title)
        })
        
        handleFetchPinList()
      } else {
        handleFetchPinList()
      }
    }
    /*
    useEffect(() => {
      if (pinListPositions.length === 0) { // Ensuring it fetches only if the list is empty
        refreshPinList();
      }
    }, [])
*/
    const handleDeletePin =async(IDInput)=> {
      //console.log(IDInput)
      await deletePin(IDInput).then(
        refreshPinList()
      )
      setCardIndex(null)
    }

    const handleUpdatePin=()=> {
      console.log('updated pin')
    }

    const handleGoToPoint = (input) => {
      //console.log({ input });
      if(isMobile) {
        closeAll();
      }
      // Set position directly using hook setter
      setPinView(true)
      setFocusedPin(input)
    };

    console.log(currentUserRoles?.includes('Admin'))

if (pinListPositions?.length === 0 || pinListPositions=== undefined) {
    return (
      <>
      { refreshLoading
      ?
      <Card>
        <Button loading></Button>
      </Card>
        :
        <Stack height='100%' justifyContent='space-between'>
        <Card style={{ margin: '20px' }}>
        
            <Typography variant="h6" component="div">
            No Pins Available
            </Typography>
            <Button variant='outlined' color='neutral'  onClick={handleAddNewPin}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <img src={BasicAssets.addLocation} alt='add location' style={{width: '15px', height:'15px' }}/>
                <Typography>Create some pins!</Typography>
              </Stack>
            
            </Button>

        </Card>
            <Button variant='outlined' color='neutral' onClick={refreshPinList} sx={{marginBottom:'30px', width:'50%', alignSelf:'center'}}>
            <img className='refreshButton' src={refresh} alt='refresh'></img>
            </Button>
            </Stack>
          }
          </>
    );
    }

  return (
    <Stack  display="flex" justifyContent="space-between" alignItems="center" height={isMobile?'100%':'92%'} sx={{width:'100%'}}>
      
      <AccordionGroup sx={{ width: '100%',}}>
  <Stack spacing={0.5}>
      {pinListPositions.map((pin, index) => (
        <Accordion 
          key={index}
          expanded={index === cardIndex}
          onChange={(event, expanded) => setCardIndex(expanded ? index : null)}
        >
          <AccordionSummary>
          <Typography fontSize={17} fontWeight='bold'>{pin.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1} >
            {pin.description && (
              <Stack spacing={-0.5}>
                <Typography level='body-xs' fontWeight={'bold'}>Description:</Typography>
                <Typography level='body-xs'   sx={{
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  }}> {pin.description}</Typography>
              </Stack>
              )}
              <Stack spacing={-0.5}>
                <Typography level='body-xs' fontWeight={'bold'}>Coordinates:</Typography>
                <Typography level='body-xs'> {`Lat: ${parseFloat(pin.latitude).toFixed(4)}, Lng: ${parseFloat(pin.longitude).toFixed(4)}`}</Typography>
              </Stack>
              <Stack spacing={1} direction='row'>
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => handleGoToPoint(index)}
                >
                  <img src={orangeLocation} alt='view location' style={{ width: '20px', height: '18px' }}/>
                </Button>
                <PinDeletionModal 
                  handleDeleteOpen={handleDeleteOpen} 
                  handleDeleteClose={handleDeleteClose} 
                  inputID={pin} 
                  handleDeletePin={handleDeletePin} 
                  openDelete={openDelete} 
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))
}
  </Stack>
</AccordionGroup>
      
      <Stack direction='row'spacing={1}  padding={2}>
        <Button color='neutral' variant='outlined' onClick={handleAddNewPin}>New Pin</Button>
        <ToggleMultiplePins coordinates={pinListPositions} closeAll={closeAll}/>
        <Button variant='outlined' color='neutral' onClick={()=>refreshPinList()} sx={{marginBottom:'30px', alignSelf:'center'}}>
          <img className='refreshButton' src={refresh} alt='refresh'></img>
        </Button>
      </Stack>
      
    </Stack>
  );
};

export default PinListDisplay;
