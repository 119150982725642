import React, {useState} from 'react';
import {View, Image, useTheme, Text} from '@aws-amplify/ui-react'
import largeLogo from '../assets/ULLRLogo.svg'
import sloganLogo from '../assets/ULLRSloganLogo.png'
import { BasicAssets } from '../assets/basicAssets';
import '../css/navbar.css'
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import { ThemeProvider, createTheme, defaultTheme } from '@aws-amplify/ui-react';
import { Card, Typography, Button, Stack, Box, Modal, ModalClose } from '@mui/joy';
import '@aws-amplify/ui-react/styles.css';
/*
https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
Use the above for customization of Login Screen
*/
const darkGrey = '#2c3332'
const midGrey='#5A5A5A'
const lightGrey='#E5E5E6'

const myTheme = createTheme({
  name: 'custom-theme',
  
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#2c3332',  
          80: '#2c3332',  
        },
      },
    },
    components: {
      authenticator: {
        router: {
          boxShadow: '0 0 0px',
          borderWidth: '1px',
          borderColor:'grey',
        },
        form: {
          padding:'20px',
          paddingBottom:'0px',
          marginBottom:'0px',
        },
        footer: {
          paddingBottom:'0px',
          
        }
      },
      button: {
        _hover: {
          //color: { value: '#C71AB6' }, // Hover color for the link
          backgroundColor: { value: lightGrey }, // Hover state color
          color: { value: darkGrey },
          borderColor: { value: darkGrey },
        },
        _active: {
          backgroundColor: { value: lightGrey },
        },
        _focus: {
          backgroundColor: { value: lightGrey },
        },
        primary: {
          backgroundColor: { value: darkGrey }, // Button background color
          color: { value: '#FFFFFF' }, // Button text color,
          _hover: {
            backgroundColor: { value: lightGrey },
            color: { value: darkGrey } // Hover state color
          },
          _active: {
            backgroundColor: { value: lightGrey },
          },
        },
        link: {
          color: { value: midGrey }, // Link text color
          backgroundColor: { value: 'white' },
          _hover: {
            //color: { value: '#C71AB6' }, // Hover color for the link
            backgroundColor: { value: darkGrey }, // Hover state color
            color: { value: 'white' }
          },
          _active: {
            backgroundColor: { value: lightGrey },
          },
        },
      },
      tabs: {

        borderColor: { value: 'white' },
        borderRadius:'10px',
        item: {
          color: { value: '#636364' },
          backgroundColor:{value:'#E5E5E6'},
          _hover: {
            color: { value: 'black' },
          },
          _focus: {
            color: { value: 'black' },
          },
          _active: {
            color: { value: 'black' },
            borderColor: { value: darkGrey },
            backgroundColor: {value: 'white' },
          },
        },
      },
      
      input: {
        backgroundColor: { value: darkGrey },
        color: { value: darkGrey },
      },
      fieldcontrol: {
        borderColor: { value: darkGrey },
      },
      
    },
  },
});

const FormFields = {
  signUp: {
    preferred_username: {
      label: 'Username',
      placeholder: 'Enter your Username',
      order: 1,
    },
    email: {
      label: 'Email Address',
      placeholder: 'Enter your email',
      order: 2,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <Card sx={{ backgroundColor:'white', zIndex:-1, marginBottom:-1, paddingBottom:2, borderColor:'grey', }}>

                <Stack spacing={2} display='flex' justifyContent={'space-between'} height='100%' width={isMobile?'80vw':'450px'} maxWidth={isMobile?'450px':'450'}>
                  <img style={{width:isMobile?'80vw':'450px', maxWidth:isMobile?'450px':'450'}} src={sloganLogo} />
                  <Typography level='body-xs' textAlign={'center'}>
                  Welcome to ULLR Maps—your trusted guide for navigating Whistler Blackcomb and beyond. Log in to access our detailed, up-to-date maps and tools designed to enhance your mountain experience. 
                  </Typography>

                </Stack>

      </Card>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <>
      <Card sx={{marginTop:-2, paddingTop:3, backgroundColor:'white', zIndex:-1, alignText:'center', borderColor:'grey'}}>
          <Typography textAlign={'center'} level='body-sm'>
            &copy; Copyright ULLR Maps Ltd. 2024
          </Typography>
      </Card>
      </>
    );
  },
  
}

export { components, myTheme, FormFields}