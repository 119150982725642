
const version = '1.2.1'
const preVersion = 'Beta'

/*
1.20
fixed notice modal for ipad
    - waiver
    - spearhead NOT NECESSARY
fixed login screen
potentially fixed offline login screen
1.2.1
hotfix for login logic
- fixed duplicate splashscreen display
- fixed login infinite loop
- fixed login kicking back to unauthenticated lgoin
*/
export {version, preVersion}