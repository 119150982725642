import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, Card, ModalClose, Input, Stack, Tooltip, Snackbar } from '@mui/joy';
import { isMobile } from 'react-device-detect';
import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';
import { fetchPinList, deletePin } from '../userDataFetching/pinFetching';
import close from '../../assets/close.png'

const PinSummaryModal = ({ inputPin, setDisableZoom, onClose }) => {
  const [open, setOpen] = React.useState(true); // Modal is open by default
  const {pinListOpen, activeIcon} = usePosition()
  const {addPin, removePin, setPinView, pinListPositions, setPinListPositions} = usePinListPosition();
  const [openDelete, setOpenDelete] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false)

  const [copied, setCopied] =useState(false)

  const handleCoordinatesCopy = () => {
        
    const textCopy = `${parseFloat(inputPin.latitude)}, ${parseFloat(inputPin.longitude)}`
    // Use the clipboard API to copy the text
    navigator.clipboard.writeText(textCopy)
      .then(() => {
        setCopied(true); // Optional: Set copied state to true if successful
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleHideAllPins= ()=> {
    setPinView(false)
    setDisableZoom(false)
  }

  const handleDeleteOpen = () =>{
    setOpenDelete(true)
  };
  const handleDeleteClose = () => {

    refreshPinList()
    setOpenDelete(false)
    setDisableZoom(false)
  };

  const handleFetchPinList= async() => {
          try {
              console.log('fetching pin list PINLISTMODAL')
              const {data} = await fetchPinList()
              console.log(data.listEntries.items)
              data.listEntries.items.forEach(({ lat, lng, title, description, id, creator }) => {
                addPin(lat, lng, title, description, id, creator);
              });
              console.log('refresh done')
              setRefreshLoading(false)
              
          } catch (error) {
              console.log('error fetching pins', error)
          }
      }
  
      const refreshPinList = () => {
        if (pinListPositions.length > 0) {
          console.log('refresh start')
          setRefreshLoading(true)
          pinListPositions.forEach(({title})=>{
            removePin(title)
          })
          
          handleFetchPinList()
        } else {
          handleFetchPinList()
        }
      }
      
      useEffect(() => {
        if (pinListPositions.length === 0) { // Ensuring it fetches only if the list is empty
          refreshPinList();
        }
      }, [])
  
      const handleDeletePin =async(IDInput)=> {
        //console.log(IDInput)
        await deletePin(IDInput).then(
        )
      }

  const handleClose = () => {
    setOpen(false);
    onClose(); // Call onClose to reset the selected pin in the parent component
    setDisableZoom(false)
    //console.log({pinListOpen})
  };

  return (
    <>
      {open  && (
        <Box
  onMouseEnter={() => setDisableZoom(true)}
  onMouseLeave={() => setDisableZoom(false)}
  sx={{
    position: 'fixed',
    bottom: isMobile?'35px':'',
    top:isMobile?'':'63px',
    left: isMobile?'10px':pinListOpen?'410px':'50px',
    minWidth: '325px',
    width: isMobile ? '95%' : '',
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 3,
    p: 2,
    zIndex: 1009,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    // minHeight: '200px', // Remove this line if height isn't fixed
  }}
>
  <Button 
    variant='outlined' 
    color='neutral' 
    onClick={handleClose}
    sx={{
      position: 'absolute',
      top: '10px', 
      right: '10px',
      width: '15px',
      height: '15px', 
    }}
  >
    <img style={{ width: '10px' }} src={close} alt='close' />
  </Button> 
  <Stack 
    justifyContent="space-between" 
    sx={{ 
      height: '100%',  // Ensures Stack fills the Box
      flexGrow: 1,     // Allow Stack to grow and take remaining space
      margin: '5px',   // Optional margin
    }}
  >
    {/* Title */}
    <Typography 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: '20px',
        wordWrap: 'break-word', 
        overflow: 'hidden', 
        textAlign: 'left',
        marginTop: '5px',
      }}
    >
      {inputPin.title}
    </Typography>

    {/* Coordinates section */}
    <Stack spacing={-0.5} marginTop="5px">
      <Typography level="body-sm" textAlign="left">
        Coordinates:
      </Typography>
      <Tooltip arrow placement="top" title={`${parseFloat(inputPin.latitude)}, ${parseFloat(inputPin.longitude)}`}>
        <Typography onClick={handleCoordinatesCopy}>
          {parseFloat(inputPin.latitude).toFixed(4)}, {parseFloat(inputPin.longitude).toFixed(4)}
        </Typography>
      </Tooltip>
    </Stack>

    {/* Snackbar */}
    <Snackbar
      size="sm"
      open={copied}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{
        marginBottom: '10px',  // Reduce or remove marginBottom
        marginLeft: '2px',
      }}
    >
      Coordinates Copied!
    </Snackbar>

    {/* Description */}
    <Typography 
      level="body-sm" 
      sx={{ 
        marginTop: '4px',
        wordWrap: 'break-word', 
        overflow: 'hidden', 
        textAlign: 'left',
        marginBottom:'8px',
      }}
    >
      {inputPin.description}
    </Typography>

    {/* Button */}
    <Stack direction='row' spacing={2} justifyContent={'center'}>
    <Button variant="outlined" color="neutral" onClick={handleHideAllPins}>
        Hide Pins
      </Button>
    <PinDeletionModal 
      handleDeleteOpen={handleDeleteOpen} 
      handleDeleteClose={handleDeleteClose} 
      inputID={inputPin} 
      handleDeletePin={handleDeletePin} 
      openDelete={openDelete} 
    />

    </Stack>

  </Stack>
</Box>

      )}
    </>
  );
};

const PinDeletionModal = ({handleDeleteOpen, handleDeleteClose, inputID, handleDeletePin, openDelete}) => {
    const fullHandle = () =>{
        handleDeleteOpen()
        handleDeletePin(inputID.id)
    }
  return (
    <div>
      <Button onClick={fullHandle} variant="outlined" color="neutral">
        Delete Pin
      </Button>

      <Modal open={openDelete} onClose={handleDeleteClose}>
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',  // 10px from the left edge
            transform: 'translateY(-50%)',  // Centers the modal vertically
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
            <Card>
                <ModalClose/>
                <Typography variant="h6" component="h2">
                    Deletion Successful
                </Typography>
            </Card>
        </Box>
      </Modal>
    </div>
  );
};

const PinUpdateModal = ({ handleUpdateOpen, handleUpdateClose, inputData, handleUpdatePin, openUpdate }) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [updatedPinData, setUpdatedPinData] = useState(inputData); 

  useEffect(() => {
    if (openUpdate) {
      setUpdatedPinData(inputData); // Update the state whenever the modal opens
    }
  }, [openUpdate, inputData]); // Ensure state is reset when the modal opens or inputData changes
  
  // Handle the first modal's submit
  const handleSubmit = () => {
    handleUpdatePin(updatedPinData); // Call update pin function with the new data
    handleUpdateClose(); // Close the first modal
    setSuccessModalOpen(true); // Open the success modal
  };

  const handleSuccessClose = () => {
    setSuccessModalOpen(false); // Close the success modal
  };
  const test = () => {
    console.log(inputData)
  }

  return (
    <div>
      {/* Button to open the update modal */}
      <Button onClick={handleUpdateOpen} variant="outlined" color="neutral">
        Update
      </Button>

      {/* First modal: for updating pin details */}
      <Modal open={openUpdate} onClose={handleUpdateClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',  // 10px from the left edge
            transform: 'translateY(-50%)',  // Centers the modal vertically
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <Card>
            <ModalClose />
            <Typography variant="h6" component="h2">
              Update Pin
            </Typography>
            <Button onClick={()=>test()}></Button>

            {/* Input fields to update the pin */}
            <Input
              label="Title"
              value={updatedPinData?.title || ''}  // Prevent rendering before data is loaded
              onChange={(e) => setUpdatedPinData({ ...updatedPinData, title: e.target.value })}
            />
            <Input
              label="Description"
              value={updatedPinData?.description || ''}  // Prevent rendering before data is loaded
              onChange={(e) => setUpdatedPinData({ ...updatedPinData, description: e.target.value })}
            />

            {/* Submit button */}
            <Button variant="outlined" color="neutral" onClick={handleSubmit}>
              Submit Update
            </Button>
          </Card>
        </Box>
      </Modal>

      {/* Second modal: for confirming the update */}
      <Modal open={successModalOpen} onClose={handleSuccessClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',  // 10px from the left edge
            transform: 'translateY(-50%)',  // Centers the modal vertically
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <Card>
            <ModalClose />
            <Typography variant="h6" component="h2">
              Pin Updated Successfully
            </Typography>
            <Button variant="outlined" color="neutral" onClick={handleSuccessClose}>
              Close
            </Button>
          </Card>
        </Box>
      </Modal>
    </div>
  );
};

// The PinCreationModal component stays the same
const PinCreationModal = ({ handleCreateClose, openCreate }) => {
  return (
    <Modal open={openCreate} onClose={handleCreateClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: isMobile? '50%':'10px', // 10px from the left edge
          transform: isMobile? 'translateY(-50%) translateX(-50%)': '', // Centers the modal vertically
          width: 325,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          borderRadius: 4,
        }}
      >
        <ModalClose />
        <Typography variant="h6" component="h2">
          Pin Creation Successful
        </Typography>
      </Box>
    </Modal>
  );
};

export {PinDeletionModal, PinCreationModal, PinUpdateModal, PinSummaryModal};